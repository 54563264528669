<script setup>

    import { util } from '@/Helpers'
    import { computed, ref } from 'vue'

    import { IconArrowSync20Filled, IconHome20Regular, IconVehicleCarProfile20Regular, IconArrowImport20Regular, IconArrowImport16Regular, IconArrowSync16Filled, IconHome16Regular, IconVehicleCarProfile16Regular } from '@iconify-prerendered/vue-fluent'

    import LatteStaffLabel from '@/Components/LatteStaffLabel.vue'

    import { useStaffStore } from '@/Stores'

    const staffStore = useStaffStore()

    const props = defineProps({
        isCurrentOrFutureWeek: {
            type: Boolean
        },
        block: {
            type: Object
        },
        size: {
            type: String
        }
    })

    const loadingImages = ref([])

</script>

<template>
    <div
        :class="[
            'overflow-hidden',
            {
                'text-lifeworx-blue-600': isCurrentOrFutureWeek,
                'text-lifeworx-blue-900': !isCurrentOrFutureWeek,
                'w-36': size == '2xl'
            }]"
        >

        <div :class="[{
            'flex flex-col items-stretch space-y-0.5': size == 'xs',
            'absolute left-0.5 top-0 flex flex-col items-stretch space-y-0.5': size == 'sm' && isCurrentOrFutureWeek,
            'space-y-0.5': size == 'md' && isCurrentOrFutureWeek,
            'space-y-1': size == 'lg' && isCurrentOrFutureWeek,
            'space-y-1': size == 'xl' && isCurrentOrFutureWeek,
            'space-y-2': size == '2xl' && isCurrentOrFutureWeek,
            'relative h-full': !isCurrentOrFutureWeek
        }]">

            <!-- Time -->
            <div v-if="['xs', 'sm', 'md', 'lg', 'xl', '2xl'].includes(size)">
                <span
                    :class="[{
                        'text-xs font-bold my-0 leading-none': size == 'xs',
                        'text-sm font-bold my-0 leading-snug': size == 'sm',
                        'text-sm font-bold my-0 leading-snug': size == 'md',
                        'text-base font-bold my-0 leading-tight': size == 'lg',
                        'text-base font-bold my-1 leading-tight': size == 'xl',
                        'text-xl font-bold my-1 leading-tight': size == '2xl',
                    }, 'whitespace-nowrap font-bold']"
                >
                    {{ util.date.to12CycleTimeParts(block.continuation ? block.shift_start_time : block.start_time)[0] }}<span class="text-xs">{{ util.date.to12CycleTimeParts(block.continuation ? block.shift_start_time : block.start_time)[1] }}</span><br />

                    &ndash;{{ util.date.to12CycleTimeParts(util.date.timeAfterMinute(block.start_time, block.continues ? block.duration+block.continuation_duration : block.duration))[0] }}<span class="text-xs">{{ util.date.to12CycleTimeParts(util.date.timeAfterMinute(block.start_time, block.continues ? block.duration+block.continuation_duration : block.duration))[1] }}</span>
                </span>
            </div>

            <!-- Types -->
            <div
                v-if="['lg', 'xl', '2xl'].includes(size) && isCurrentOrFutureWeek"
                :class="[{
                    'space-y-1': size == 'lg',
                    'space-y-1.5 ': size == 'xl',
                    'space-y-1.5 text-sm': size == '2xl',
                }]"
            >
                <div>
                    <IconArrowImport20Regular v-if="block.end_date" class="inline-flex h-5 w-5 mr-1" />
                    <IconArrowSync20Filled v-else class="inline-flex h-5 w-5 mr-1" />
                    <span class="font-bold tracking-tight">{{ block.end_date ? 'Temp' : 'Ongoing' }}</span>
                </div>
                <div>
                    <IconHome20Regular v-if="block.stay_type == 'live_in'" class="inline-flex h-5 w-5 mr-1" />
                    <IconVehicleCarProfile20Regular v-else class="inline-flex h-5 w-5 mr-1" />
                    <span class="font-bold tracking-tight">{{ block.stay_type == 'live_in' ? 'Live-In' : 'Live-Out'}}</span>
                </div>
            </div>
            <div
                v-if="['md'].includes(size) && isCurrentOrFutureWeek"
                :class="[{
                    'font-bold space-y-0.5': size == 'md',
                }]"
            >
                <div>
                    <IconArrowImport16Regular v-if="block.end_date" class="inline-flex h-4 w-4 mr-1" />
                    <IconArrowSync16Filled v-else class="inline-flex h-4 w-4 mr-1" />
                    <span>{{ block.end_date ? 'Temp' : 'Ongoing' }}</span>
                </div>
                <div>
                    <IconHome16Regular v-if="block.stay_type == 'live_in'" class="inline-flex h-4 w-4 mr-1" />
                    <IconVehicleCarProfile16Regular v-else class="inline-flex h-4 w-4 mr-1" />
                    <span>{{ block.stay_type == 'live_in' ? 'Live-In' : 'Live-Out'}}</span>
                </div>
            </div>
            <div v-if="['sm'].includes(size) && isCurrentOrFutureWeek">
                <IconArrowImport16Regular v-if="block.end_date" class="inline-flex h-4 w-4" />
                <IconArrowSync16Filled v-else class="inline-flex h-4 w-4" />
                <IconHome16Regular v-if="block.stay_type == 'live_in'" class="inline-flex h-4 w-4" />
                <IconVehicleCarProfile16Regular v-else class="inline-flex h-4 w-4" />
            </div>

            <!-- Rate -->
            <div v-if="['md', 'lg', 'xl', '2xl'].includes(size) && util.schedule.rateOf(block)">
                <span class="text-sm font-bold">{{ util.schedule.rateOf(block) }}<span class="text-2xs font-semibold">/{{ block.charge_duration_rate_type == 'hourly' ? 'HR' : 'day' }}</span></span>
            </div>

            <!-- Assignment -->
            <div
                v-if="['lg', 'xl', '2xl'].includes(size) || (!isCurrentOrFutureWeek && !['3xs', '2xs', 'xs', 'sm'].includes(size))"
                :class="[
                    'font-semibold flex flex-col',
                    {
                        'mt-1': ['md', 'lg'].includes(size),
                        'mt-2': ['xl', '2xl'].includes(size),
                    }
                ]"
            >
                <div class="flex flex-row -mt-1 mb-1 place-items-center">
                    <div
                        :class="[
                            'flex tracking-tight font-semibold text-sm text-wrap text-lifeworx-blue-800',
                        ]"
                    >
                        {{ block.client?.id ? `${block.client.first_name} ${block.client.last_name}` : ''}}
                    </div>
                </div>
                <LatteStaffLabel
                    :actionable="false"
                    :staff="block.client?.assigned_sales_manager"
                    size="xs"
                    :wrapping="true"
                />
            </div>

        </div>
    </div>
</template>
