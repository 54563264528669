<script setup>

    import { ref, watch } from 'vue'
    import TextInput from './TextInput.vue';

    const props = defineProps({
        modelValue: {
            type: [String, Number, null],
        },
        validate: {
            type: Array,
            default: []
        },
        placeholder: {
            type: String,
            default: ''
        },
        input_class: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        unit: {
            type: [String, null],
            default: null
        },
        currency: {
            type: [String, null],
            default: null
        }
    })

    const emit = defineEmits(['update:modelValue'])
    const text = ref(null)
    const textInput = ref(null)
    const input = ref(null)

    function sanitize(e) {
        text.value = text.value.toString().replace(/[^0-9]/g, '').replace(/^0+/, '')
        if (e.data == 0) {
            if (text.value == '' && props.modelValue == null) {
                return
            }

            if (text.value == props.modelValue) {
                setTimeout(function() {
                    input.value.setSelectionRange(0, 0)
                }, 0)

                return
            }
        }

        emit('update:modelValue', text.value)
    }

    function focusInput() {
        input.value.focus()
    }

    watch(() => props.modelValue, (value) => {
        text.value = value
    }, {immediate: true})


</script>

<template>
    <div class="relative">
        <span
            v-if="props.currency"
            @click="focusInput"
            class="absolute left-2 top-2 text-sm text-lifeworx-blue-800 text-opacity-60 select-none cursor-text"
        >{{props.currency}}</span>
        <TextInput
            :ref="textInput"
            :class="{
                [props.input_class]: true,
                'pr-10': props.unit,
                'pl-5': props.currency
            }"
            type="text"
            :validate="props.validate"
            :placeholder="props.placeholder"
            :disabled="props.disabled"
            @input="sanitize"
            @reference:set="(value) => input = value"
            v-model="text"
        />
        <span
            v-if="props.unit"
            @click="focusInput"
            class="absolute right-2 top-2 text-sm text-lifeworx-blue-800 text-opacity-60 select-none cursor-text"
        >/ {{props.unit}}</span>
    </div>
</template>
