<script setup>
    import { computed, ref, watch } from 'vue';
    import LatteCaregiverScheduleView from '@/Components/Caregiver/LatteCaregiverScheduleView.vue';

    import { useCaregiverClientShiftStore, useCaregiverInvoiceShiftStore, useCaregiverSingleStore, useFormStatusStore, useCaregiverAvailabilityStore, useNotificationStore } from '@/Stores'
    import { debounce, deepCopy, getModifiedProperties, util } from '@/Helpers';
    import LatteCaregiverScheduleBuilder from '@/Components/Caregiver/LatteCaregiverScheduleBuilder.vue';
    import LatteCaregiverScheduleFooter from '@/Components/Caregiver/LatteCaregiverScheduleFooter.vue';
    import { useJobRunner } from '@/Helpers/jobRunner';
    import Loader from '@/Components/App/Loader.vue'
    import { storeToRefs } from 'pinia';

    import moment from 'moment';
    import { IconCalendarError24Filled } from '@iconify-prerendered/vue-fluent';

    const formStatus = useFormStatusStore()

    const caregiverClientShiftStore = useCaregiverClientShiftStore()
    const caregiverInvoiceShiftStore = useCaregiverInvoiceShiftStore()
    const caregiverAvailabilityStore = useCaregiverAvailabilityStore()
    const caregiverSingleStore = useCaregiverSingleStore()
    const notificationStore = useNotificationStore()

    const { caregiver, userFocusedOn } = storeToRefs(caregiverSingleStore)

    const formAvailabilityLoading = formStatus.computedFormLoading('caregiverAvailabilityForm')
    const formCaregiverLoading = formStatus.computedFormLoading('caregiver')

    const emit = defineEmits(['caregiver:change'])

    const scheduleState = ref(computeScheduleState([]))
    let availabilities
    let liveInShifts
    let liveOutGroupOneShifts
    let liveOutGroupTwoShifts

    const jobRunner = useJobRunner('caregiver-availability')

    const props = defineProps({
        caregiver_id : {
            type: Number,
            required: true
        },
        schedule_start_date: {
            type: String,
            required: true
        }
    })

    jobRunner.subscribe('addedRetryJob', function(errorCode, retryTime, meta) {
        notificationStore.add(`Error Saving Availability Changes for ${meta.group_name}`, 'error', `Retrying automatically... (${retryTime}/20)`, 15000)
    })
    jobRunner.subscribe('addedJob', function(meta) {
        notificationStore.removeById(caregiverAvailabilityStore.getMaxRetryDialogId(meta.group_name))
        caregiverAvailabilityStore.removeMaxRetryDialogId(meta.group_name)
    })
    jobRunner.subscribe('reachedMaxRetry', function(meta) {
        const maxRetryDialogId = notificationStore.add('Error Saving Changes', 'error', `Latest changes for availability ${meta.group_name} not saved. Make an edit to try again.`, 7 * 24 * 60 * 60 * 1000)
        caregiverAvailabilityStore.setMaxRetryDialogId(maxRetryDialogId, meta.group_name)
    })

    function createOrUpdateAvailability(availabilityUuid, data, formId, meta) {
        caregiverAvailabilityStore.applyLocalChangeToAvailability(props.caregiver_id, availabilityUuid, data)
        jobRunner.addJob('create_update', availabilityUuid, data, [formId], async (data, formId, jobId) => {
            await caregiverAvailabilityStore.createOrUpdateAvailability(props.caregiver_id, availabilityUuid, data, formId, jobId)
                .then(function (response) {
                    if (jobRunner.hasScheduleJob('create_update', availabilityUuid) == false) {
                        caregiverAvailabilityStore.replaceAvailability(props.caregiver_id, response.data)
                    }
                    jobRunner.handleJobSuccess('create_update', availabilityUuid, jobId)
                    notificationStore.add('Changes Saved')
                })
                .catch(function(error) {
                    jobRunner.handleJobError('create_update', availabilityUuid, jobId, error.error.response?.status)
                })
        }, meta)
    }


    const fields = ['start_time', 'duration', 'day_of_week']
    function handleScheduleChange(data) {
        if (data.live_in[0].id != scheduleState.value.live_in[0].id) {
            console.error('Mismatch live in data between builder and schdule')
        }

        if (data.live_out[0].id != scheduleState.value.live_out[0].id) {
            console.error('Mismatch live out data between builder and schdule')
        }

        if (data.live_out[1].id != scheduleState.value.live_out[1].id) {
            console.error('Mismatch live out data between builder and schdule')
        }

        function diffToRequestData(originalData, diff) {
            if (Object.keys(diff).length == 0) {
                return null
            }

            const final = originalData.fe_generated_not_sync ? {...originalData} : {}
            Object.keys(diff).forEach(key => {
                if (key != 'day_of_week') {
                    final[key] = diff[key]
                } else {
                    originalData[key].forEach(day => {
                        final[day] = false
                    })
                    diff[key].forEach(day => {
                        final[day] = true
                    })
                }
            })

            if (final.stay_type == undefined) {
                final.stay_type = originalData['stay_type']
            }

            if (final.start_time) {
                final.start_time = util.date.toUtcTime(final.start_time)
            }

            return final
        }

        function process(stay_type, pos = 0)
        {
            const requestData = diffToRequestData(
                scheduleState.value[stay_type][pos],
                getModifiedProperties(
                    JSON.stringify(data[stay_type][pos]),
                    JSON.stringify(scheduleState.value[stay_type][pos]),
                    fields
                )
            )

            if (requestData != null) {
                let groupName = stay_type.split('_').join(' ')
                if (stay_type == 'live_out') {
                    groupName += ` group ${pos + 1}`
                }
                createOrUpdateAvailability(scheduleState.value[stay_type][pos].uuid, requestData, undefined, {
                    'group_name' : groupName
                })
            }
        }

        process('live_in')
        process('live_out', 0)
        process('live_out', 1)
    }

    function handlePreferenceChange(data) {
        const diff = getModifiedProperties(
            JSON.stringify(data),
            JSON.stringify(caregiver.value),
            [
                'availability_pref_live_in', 'availability_pref_live_out', 'availability_pref_day_work',
                'availability_pref_night_work',
                'availability_pref_fill_ins_flexible',
                'availability_pref_max_travel_time',
                'availability_pref_min_hourly_rate',
                'availability_pref_min_daily_rate',
                'availability_pref_live_in_min_days_per_week',
                'availability_pref_live_in_max_days_per_week',
                'availability_pref_live_out_min_days_per_week',
                'availability_pref_live_out_max_days_per_week',
                'availability_pref_live_out_min_hours_per_day',
                'availability_pref_live_out_max_hours_per_day',
                'availability_notes',
            ]
        )

        if (Object.keys(diff).length) {
            emit('caregiver:change', diff, 'caregiverAvailabilityPrefForm')
        }
    }

    function handleLastConfirmedDateUpdate(date) {
        if (date) {
            emit('caregiver:change', {
                last_confirmed_date: date
            }, 'caregiverAvailabilityPrefForm')
        }
    }

    function handleScheduleConfirm() {
        const now = moment().utc().format('YYYY-MM-DD HH:mm:ssZ');
        handleLastConfirmedDateUpdate(now)
    }

    function retrieveShifts() {
        if (!props.caregiver_id) {
            return
        }

        if (props.schedule_start_date >= util.date.lastSunday()) {
            const lowerBoundDateTime = util.date.dateToUtcDateTime(props.schedule_start_date)
            const upperBoundDateTime = util.date.nextUtcDateTime(props.schedule_start_date, 7 * 5, false)
            caregiverClientShiftStore.retrieveShifts(props.caregiver_id, lowerBoundDateTime, upperBoundDateTime, 'caregiverAvailabilityForm')
                .then(response => {
                    caregiverClientShiftStore.replaceLocalStoreShifts(props.caregiver_id, response.data)
                })

            caregiverAvailabilityStore.retrieveAvailabilities(props.caregiver_id, 'caregiverAvailabilityForm')
                .then(response => {
                    caregiverAvailabilityStore.replaceLocalAvailabilities(props.caregiver_id, response.data)
                })
        } else {
            const upperBound = util.date.nextDate(props.schedule_start_date, 6)
            caregiverInvoiceShiftStore.retrieveShifts(props.caregiver_id, props.schedule_start_date, upperBound, 'caregiverAvailabilityForm')
                .then(response => {
                    caregiverInvoiceShiftStore.replaceLocalStoreShifts(props.caregiver_id, response.data)
                })
        }

    }

    const retrieveShiftDebounce = debounce(() => {
        retrieveShifts()
    }, 300)

    watch([() => props.schedule_start_date, () => props.caregiver_id], (newValue, oldValue) => {
        if (newValue[0] != oldValue[0] || newValue[1] != oldValue[1]) {
            retrieveShiftDebounce()
        }
    }, {immediate: true, deep: true})

    function scheduleStateToShift(stay_type, pos = 0) {
        const stayTypeData = scheduleState.value[stay_type][pos]
        const dayOfWeek = {}
        stayTypeData['day_of_week'].forEach(day => dayOfWeek[day] = true)

        const dayOfWeekWithData = Object.keys(dayOfWeek).filter(day => dayOfWeek[day])

        return dayOfWeekWithData.map(day => ({
            start_time: stayTypeData['start_time'],
            duration: stayTypeData['duration'],
            start_date: day != 'saturday' ? util.date.nextDayOfWeekDate(props.schedule_start_date, day) : util.date.previousDate(props.schedule_start_date),
            day_of_week: day,
            stay_type: stay_type
        }))
    }
    watch(() => props.caregiver_id, (value) => {
        if (value == null) {
            return
        }

        availabilities = caregiverAvailabilityStore.computedAvailabilties(value)

        watch(availabilities, (newValue) => {
            scheduleState.value = computeScheduleState(newValue)
        }, {immediate: true, deep: true})

        liveInShifts = computed(() => {
            if (scheduleState.value == null) {
                return []
            }

            if (props.schedule_start_date < util.date.lastSunday()) {
                return []
            }

            return scheduleStateToShift('live_in')
        })

        liveOutGroupOneShifts = computed(() => {
            if (scheduleState.value == null) {
                return []
            }

            if (props.schedule_start_date < util.date.lastSunday()) {
                return []
            }

            return scheduleStateToShift('live_out', 0)
        })

        liveOutGroupTwoShifts = computed(() => {
            if (scheduleState.value == null) {
                return []
            }

            if (props.schedule_start_date < util.date.lastSunday()) {
                return []
            }

            return scheduleStateToShift('live_out', 1)
        })
    }, { immediate: true, deep: true})

    let caregiverInvoiceShifts
    watch([() => props.caregiver_id, () => props.schedule_start_date], ([local_caregiver_id, local_start_date]) => {
        caregiverInvoiceShifts = caregiverInvoiceShiftStore.computedShift(local_caregiver_id)
    })

    function computeScheduleState(existingAvailabilities) {
        const result = {
            'live_in' : [],
            'live_out' : [],
        }

        function availabilityToBuilderConfig(availabilityObject) {
            const newObject = {
                uuid: availabilityObject.uuid,
                start_time: util.date.toEasternTime(availabilityObject.start_time),
                duration: availabilityObject.duration,
                stay_type: availabilityObject.stay_type,
                day_of_week: []
            }

            const weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
            weekDays.forEach((day) => {
                if (availabilityObject[day]) {
                    newObject.day_of_week.push(day)
                }
            })

            return newObject
        }

        existingAvailabilities.forEach(availability => {
            if (availability.stay_type == 'live_in') {
                if (result['live_in'].length == 0) {
                    result['live_in'].push(availabilityToBuilderConfig(availability))
                }
            } else {
                if (result['live_out'].length <= 2) {
                    result['live_out'].push(availabilityToBuilderConfig(availability))
                }
            }
        })

        if (result['live_in'].length == 0) {
            result['live_in'].push(caregiverAvailabilityStore.defaultScheduleState('live_in'))
        }

        while (result['live_out'].length < 2) {
            result['live_out'].push(caregiverAvailabilityStore.defaultScheduleState('live_out'))
        }
        return result
    }


</script>

<template>
    <div class="relative">
        <div
            v-if="formCaregiverLoading || formAvailabilityLoading"
            class="absolute z-overlay w-full h-full flex items-center space-around"
        >
            <div class="bg-lifeworx-blue-800 opacity-50 w-full h-full absolute" />
            <Loader />
        </div>

        <div
            v-if="!(formCaregiverLoading || formAvailabilityLoading) && props.schedule_start_date < util.date.lastSunday() && caregiverInvoiceShifts && caregiverInvoiceShifts.length == 0"
            class="absolute z-content w-full h-full flex items-center space-around"
        >
            <div class="w-full h-full flex flex-col bg-gradient-radial from-stone-100/100 via-stone-100/50 to-white/50 justify-center items-center">
                <IconCalendarError24Filled class="flex-none flex-shrink-0 inline-flex h-14 w-14 mb-2 text-stone-400/75" />
                <h3 class="text-2xl font-semibold text-stone-400/75">No Shifts This Week</h3>
            </div>
        </div>

        <div class="z-content" id="caregiver_schedule">
            <LatteCaregiverScheduleBuilder
                v-if="props.schedule_start_date >= util.date.lastSunday()"
                :start_date="props.schedule_start_date"
                :schedule="scheduleState"
                :caregiver="caregiver"
                @preference-data:change="handlePreferenceChange"
                @schedule-data:change="handleScheduleChange"
            />

            <LatteCaregiverScheduleView
                :caregiver_id="parseInt(props.caregiver_id)"
                :schedule_start_date="props.schedule_start_date"
                :formLoading="formAvailabilityLoading"
                :live_in_shifts="liveInShifts"
                :live_out_group_one_shifts="liveOutGroupOneShifts"
                :live_out_group_two_shifts="liveOutGroupTwoShifts"
            />

            <LatteCaregiverScheduleFooter
                v-if="props.schedule_start_date >= util.date.lastSunday()"
                :caregiver_id="props.caregiver_id"
                :last_confirmed_date="caregiver.confirmed_at"
                :last_confirmed_staff="caregiver.confirmed_staff ? caregiver.confirmed_staff : null"
                @schedule:confirm="handleScheduleConfirm"
            />

        </div>
    </div>
</template>
