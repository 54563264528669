<script setup>

    import { computed } from 'vue';
    import VueDatePicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css'
    import { util } from '@/Helpers';
    import moment from 'moment';
    import { CalendarIcon } from '@heroicons/vue/24/outline'

    const props = defineProps({
        modelValue: {
            required: true
        },
        minDate: {
            type: [String, null],
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        teleport: {
            type: [String, Boolean],
            default: false
        },
        clearable: {
            type: [String, Boolean],
            default: false
        }
    });

    const emit = defineEmits(['update:modelValue']);

    const internalValue = computed({
        //Since we set the eastern timezone in util. Moment will first parse the date in UTC and then convert to eastern timezone
        //We add 23:59:59 to preserve the date so VueDatePicker can pick up the correct day
        get: () => moment(`${props.modelValue} 23:59:59`).toDate(),
        set: (value) => emit('update:modelValue', util.date.toDateString(value)),
    });

</script>

<template>
    <div>
        <VueDatePicker
            v-model="internalValue"
            :min-date="moment(props.minDate).toDate()"
            :enable-time-picker="false"
            week-start="0"
            :autoApply="true"
            :teleport="props.teleport"
            :disabled="props.disabled"
            :clearable="props.clearable"
            format="MM/dd/yyyy"
            :ui="{ input: 'font-semibold rounded-lg text-center border-stone-400 focus:border focus:border-lifeworx-blue-600 focus:ring-0 focus:outline focus:outline-3 ' }"
        >
            <template #input-icon>
                <CalendarIcon class="inline-flex mx-2 h-5 w-5 -mt-0.5 text-stone-500 input-slot-image" />
            </template>
        </VueDatePicker>
    </div>
</template>
