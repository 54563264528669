import { computed, reactive, ref } from 'vue';

import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia';
import { useConfigStore, useFormStatusStore } from '@/Stores'

import * as Sentry from "@sentry/vue";
import { useNProgress } from '@vueuse/integrations/useNProgress';
import { get } from '@/Helpers/takeLatestResponseRequest';
import { util } from '@/Helpers';

export const useClientShiftStore = defineStore('clientShift', () => {
    const formStatusStore = useFormStatusStore()
    const configStore = useConfigStore()
    const { config } = storeToRefs(configStore)

    const { isLoading } = useNProgress()
    const loading = ref(isLoading)

    const shiftDict = reactive({})

    function replaceLocalStoreClientShifts(client_id, shifts) {
        shiftDict[client_id] = shifts.map(shift => util.schedule.toClientScheduleSupportShift(shift))
    }

    function addClientShiftsToLocalStore(client_id, shifts) {
        if (!shiftDict[client_id]) {
            shiftDict[client_id] = []
        }
        shiftDict[client_id] = shiftDict[client_id].concat(shifts.map(shift => util.schedule.toClientScheduleSupportShift(shift)))
    }

    function addOrReplaceClientShiftInLocalStore(client_id, shifts) {
        if (!shiftDict[client_id]) {
            shiftDict[client_id] = []
            shiftDict[client_id] = shiftDict[client_id].concat(shifts.map(shift => util.schedule.toClientScheduleSupportShift(shift)))
            return
        }

        const existingShiftsMap = {};
        shiftDict[client_id].forEach(shift => {
            existingShiftsMap[shift.id] = shift
        });

        shifts.forEach(newShift => {
            existingShiftsMap[newShift.id] = util.schedule.toClientScheduleSupportShift(newShift)
        });

        shiftDict[client_id] = Object.values(existingShiftsMap)
    }

    function computedClientShift(client_id) {
        return computed(() => shiftDict[client_id] ? shiftDict[client_id] : [])
    }

    function computedClientShiftMap(client_id) {
        return computed(() => {
            const map = {}

            if (shiftDict[client_id]) {
                shiftDict[client_id].forEach((shift, index) => {
                    map[shift.id] = index
                })
            }

            return map
        })
    }

    async function retrieveShifts(client_id, lowerBoundDateTime, upperBoundDateTime, formId) {
        loading.value = true
        if (formId) {
            formStatusStore.addLoading(formId)
        }

        return await new Promise((resolve, reject) => {
            get(
                `/api/v1/clients/${client_id}/shifts`,
                {
                    params: {
                        start_datetime: lowerBoundDateTime,
                        end_datetime: upperBoundDateTime
                    }
                },
            ).then(response => {
                resolve(response)
            }).catch((err) => {
                reject({ "error": err })
            }).finally(() => {
                loading.value = false
                if (formId) {
                    formStatusStore.removeLoading(formId)
                }
            })
        });
    }

    async function storeShift(client, shifts, formId) {
        loading.value = true
        if (formId) {
            formStatusStore.addLoading(formId)
        }

        return await new Promise((resolve, reject) => {
            axios.post('/api/v1/clients/' + client.id + '/shifts', shifts)
                .then(function (response) {
                    resolve(response)
                }).catch(function (error) {
                    reject({ "error": error })
                    Sentry.captureException(error)
                }).finally(() => {
                    loading.value = false
                    if (formId) {
                        formStatusStore.removeLoading(formId)
                    }
                })
        });
    }

    async function updateShifts(client_id, shiftChanges, range_start_datetime, range_end_datetime, formId) {
        loading.value = true
        if (formId) {
            formStatusStore.addLoading(formId)
        }

        const cleanShiftChange = shiftChanges.map(shift => {
            //Clean temporary value that won't be updated by backend
            delete shift['start_date']
            delete shift['start_time']
            delete shift['end_date']

            return shift
        })

        const data = {
            shifts: cleanShiftChange
        }
        if (range_start_datetime) {
            data['range_start_datetime'] = range_start_datetime
        }
        if (range_end_datetime) {
            data['range_end_datetime'] = range_end_datetime
        }

        return await new Promise((resolve, reject) => {
            axios.patch('/api/v1/clients/' + client_id + '/shifts', data).then(function (response) {
                resolve(response)
            }).catch(function (error) {
                reject({ "error": error })
                Sentry.captureException(error)
            }).finally(() => {
                loading.value = false
                if (formId) {
                    formStatusStore.removeLoading(formId)
                }
            })
        });
    }

    async function deleteShifts(client_id, shift_ids, start_datetime, end_datetime, formId) {
        loading.value = true
        if (formId) {
            formStatusStore.addLoading(formId)
        }

        const params = {}

        if (shift_ids) {
            params['shift_ids'] = shift_ids.join(',')
        }
        if (start_datetime) {
            params['start_datetime'] = start_datetime
        }
        if (end_datetime) {
            params['end_datetime'] = end_datetime
        }

        return await new Promise((resolve, reject) => {
            axios.delete(`/api/v1/clients/${client_id}/shifts`, {
                params: params
            }).then(function (response) {
                resolve(response)
            }).catch(function (error) {
                reject({ "error": error })
                Sentry.captureException(error)
            }).finally(() => {
                loading.value = false
                if (formId) {
                    formStatusStore.removeLoading(formId)
                }
            })
        });
    }

    return {
        storeShift, retrieveShifts, replaceLocalStoreClientShifts, addClientShiftsToLocalStore,
        updateShifts, addOrReplaceClientShiftInLocalStore,
        deleteShifts,
        computedClientShift, computedClientShiftMap
    }

})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useClientShiftStore, import.meta.hot))
}
