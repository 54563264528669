<script setup>

    import { computed, ref, watch } from 'vue'
    import { deepCopy, util } from '@/Helpers';
    import CaregiverSelect from '@/Components/Forms/CaregiverSelect.vue';
    import RadioInput from '@/Components/Forms/RadioInput.vue';
    import LwxSelect from '@/Components/Forms/LwxSelect.vue';
    import InputLabel from '@/Components/Forms/InputLabel.vue';
    import TimeRangePicker from '@/Components/Forms/TimeRangePicker.vue';
    import DateRangePicker from '@/Components/Forms/DateRangePicker.vue';
    import WeekDaySelect from '@/Components/Forms/WeekDaySelect.vue';
    import moment from 'moment';
    import '@vuepic/vue-datepicker/dist/main.css'
    import { IconCalendarAdd28Regular } from '@iconify-prerendered/vue-fluent'
    import AreaLabel from '@/Components/AreaLabel.vue';
    import { useConfigStore } from '@/Stores';
    import { storeToRefs } from 'pinia';
    import { LockClosedIcon } from '@heroicons/vue/20/solid';

    const emit = defineEmits(['shifts:save', 'shifts:update', 'shifts-validated-data:change', 'builder-form:reset', 'shifts:delete'])

    const configStore = useConfigStore()
    const { config } = storeToRefs(configStore)
    const isStartDatePristine = ref(true)

    const props = defineProps({
        start_date: {
            type: [String, null],
            required: true,
        },
        client_type: {
            type: [String, null],
            required: false,
            default: null
        },
        edit_state: {
            type: [Object, null],
            required: true
        },
        refresh: {
            type: Boolean,
            required: true
        },
        charge_rates: {
            type: Array,
            default: []
        }
    })

    const defaultState = {
        stay_type: 'live_out',
        duration_type: 'ongoing',
        min_charge_rate_amount: null,
        max_charge_rate_amount: null,
        charge_rate_amount: null,
        charge_duration_rate_type: 'hourly',
        start_time: '09:00',
        duration: 720,
        assigned_caregiver: {
            id: undefined,
            first_name: undefined,
            last_name: undefined,
        },
        start_date: util.date.lastSunday(),
        end_date: null,
        dayOfWeeks: []
    }
    const shiftState = ref({...defaultState, start_date: props.start_date})

    function extractOutput() {
        const info = deepCopy(shiftState.value)

        info.min_charge_rate_amount = info.min_charge_rate_amount ? info.min_charge_rate_amount.id : null
        info.max_charge_rate_amount = info.max_charge_rate_amount ? info.max_charge_rate_amount.id : null

        if (props.client_type === 'client') {
            info.charge_rate_amount = calculateAdjustedAmount(info.charge_duration_rate_type)

            if (info.charge_rate_amount == 'Multiple') {
                info.charge_rate_amount_dict = {
                    'weekend' : util.schedule.getChargeRateAmount(
                        shiftState.value.assigned_caregiver?.id,
                        'saturday',
                        info.charge_duration_rate_type,
                        props.charge_rates
                    ),
                    'weekday' : util.schedule.getChargeRateAmount(
                        shiftState.value.assigned_caregiver?.id,
                        'monday',
                        info.charge_duration_rate_type,
                        props.charge_rates
                    )
                }
            }
        }

        return info
    }

    function updateStartTime(newStartTime) {
        shiftState.value.start_time = newStartTime
    }

    function updateDuration(newDuration) {
        shiftState.value.duration = newDuration
    }

    function updateStartDate(newStartDate) {
        isStartDatePristine.value = false
        shiftState.value.start_date = newStartDate
    }

    function updateEndDate(newEndDate) {
        shiftState.value.end_date = newEndDate
    }

    function saveShifts() {
        if (props.edit_state == null) {
            emit('shifts:save', extractOutput())
        } else {
            if (hasShiftStateChanged(extractOutput(), props.edit_state)) {
                emit('shifts:update', extractOutput(), null)
            }
        }
    }

    function reset() {
        shiftState.value = {...defaultState, start_date: props.start_date}
        isStartDatePristine.value = true
        emit('builder-form:reset')
    }

    watch(() => props.start_date, (newValue) => {
        if (isStartDatePristine.value) {
            shiftState.value.start_date = newValue
        }
    })

    watch(shiftState, (newValue) => {
        if (newValue.stay_type == 'live_out') {
            shiftState.value.charge_duration_rate_type = 'hourly'
        }
        if (newValue.duration_type == 'ongoing') {
            shiftState.value.end_date = null
        } else if (shiftState.value.end_date == null) {
            shiftState.value.end_date = moment(shiftState.value.start_date, util.date.date_format).add(1, 'week').format(util.date.date_format)
        }
    }, { immediate: true, deep: true })

    const editMode = computed(() => {
        return props.edit_state != null
    })

    watch(() => shiftState, (newValue) => {
        emit('shifts-validated-data:change', extractOutput())
    }, {immediate: true, deep: true})

    watch(() => props.edit_state, (newValue, oldValue) => {
        if (newValue == null) {
            shiftState.value = {...defaultState, start_date: props.start_date}
            return
        }

        if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
            applyEditState(newValue)
        }
    })

    function hasShiftStateChanged(currentState, previousState) {
        return (
            currentState.start_date !== previousState.start_date ||
            currentState.end_date !== previousState.end_date ||
            currentState.start_time !== previousState.start_time ||
            currentState.assigned_caregiver_id !== previousState.assigned_caregiver_id ||
            currentState.stay_type !== previousState.stay_type ||
            currentState.min_charge_rate_amount !== previousState.min_charge_rate_amount ||
            currentState.max_charge_rate_amount !== previousState.max_charge_rate_amount ||
            currentState.charge_duration_rate_type !== previousState.charge_duration_rate_type ||
            currentState.duration !== previousState.duration ||
            currentState.assigned_caregiver !== previousState.assigned_caregiver
        );
    }

    function applyEditState(stateValue)
    {

        const editState = {
            ...stateValue
        }

        if (stateValue.min_charge_rate_amount) {
            editState.min_charge_rate_amount = {
                id: parseFloat(stateValue.min_charge_rate_amount),
                name: util.currency(parseFloat(stateValue.min_charge_rate_amount)) + '/' + (stateValue.charge_duration_rate_type == 'daily' ? 'day' : 'hr')
            }
        }

        if (stateValue.max_charge_rate_amount) {
            editState.max_charge_rate_amount = {
                id: parseFloat(stateValue.max_charge_rate_amount),
                name: util.currency(parseFloat(stateValue.max_charge_rate_amount)) + '/' + (stateValue.charge_duration_rate_type == 'daily' ? 'day' : 'hr')
            }
        }

        Object.keys(editState).forEach(key => shiftState.value[key] = editState[key])
    }

    function calculateAdjustedAmount(durationType, currency = false) {
        let weekendBaseAmount
        let weekdayBaseAmount

        if (weekendsSelected.value) {
            weekendBaseAmount = util.schedule.getChargeRateAmount(
                shiftState.value.assigned_caregiver?.id,
                'saturday',
                durationType,
                props.charge_rates
            )
        }

        if (weekdaysSelected.value) {
            weekdayBaseAmount = util.schedule.getChargeRateAmount(
                shiftState.value.assigned_caregiver?.id,
                'monday',
                durationType,
                props.charge_rates
            )
        }

        if (weekendBaseAmount && weekdayBaseAmount) {
            if (currency) {
                return weekendBaseAmount == weekdayBaseAmount ? util.currency(weekendBaseAmount) : 'Multiple'
            } else {
                return weekendBaseAmount == weekdayBaseAmount ? weekendBaseAmount : 'Multiple'
            }
        }

        if (weekendBaseAmount && !weekdaysSelected.value) {
            return currency ? util.currency(weekendBaseAmount) : weekendBaseAmount
        }

        if (weekdayBaseAmount && !weekendsSelected.value) {
            return currency ? util.currency(weekdayBaseAmount) : weekdayBaseAmount
        }

        return ''
    };

    watch(() => props.refresh, (newValue, oldValue) => {
        if (newValue = true) {
            isStartDatePristine.value = true
            shiftState.value = {...defaultState, start_date: props.start_date}
        }
    }, {immediate: true})

    function clearRateFields() {
        shiftState.value.min_charge_rate_amount = null
        shiftState.value.max_charge_rate_amount = null
    }

    watch(shiftState, (newValue) => {
        if (newValue.stay_type == 'live_out') {
            shiftState.value.charge_duration_rate_type = 'hourly'
            if (shiftState.value.duration > 16 * 60) {
                shiftState.value.duration = 16 * 60
            }
        }

        if (newValue.stay_type == 'live_in') {
            shiftState.value.duration = 24 * 60
        }

        if (newValue.duration_type == 'ongoing') {
            shiftState.value.end_date = null
        } else if (shiftState.value.end_date == null) {
            shiftState.value.end_date = moment(shiftState.value.start_date, util.date.date_format).add(1, 'week').format(util.date.date_format)
        }
    }, { immediate: true, deep: true })



    const hourlyRates = computed(() => {
        return config.value.prospect_rates.hourly.map(rate => ({
            id: rate,
            name: util.currency(rate) + '/hr'
        }))
    })

    const dailyRates = computed(() => {
        return config.value.prospect_rates.daily.map(rate => ({
            id: rate,
            name: util.currency(rate) + '/day'
        }))
    })

    const actionAreaTitle = computed(() => {
        if (editMode.value) {
            return 'Editing Care Schedule'
        }

        return 'Create a New Care Schedule'
    })

    const weekdaysSelected = computed(() => {
        const weekend = ['saturday', 'sunday'];

        return shiftState.value.dayOfWeeks.length > 0 &&
            shiftState.value.dayOfWeeks.some(day => !weekend.includes(day));
    });

    const weekendsSelected = computed(() => {
        const weekend = ['saturday', 'sunday'];

        return shiftState.value.dayOfWeeks.length > 0 &&
            shiftState.value.dayOfWeeks.some(day => weekend.includes(day));
    });

    const finalHourlyChargeAmount = computed(() => {
        if (props.client_type === 'client') {
            return calculateAdjustedAmount('hourly', true)
        }
        return null
    });

    const finalDailyChargeAmount = computed(() => {
        if (props.client_type === 'client') {
            return calculateAdjustedAmount('daily', true)
        }
        return null
    });

    const clearSectionButtonDisable = computed(() => {
        return props.edit_state == null && shiftState.value.dayOfWeeks.length == 0 && isStartDatePristine.value == true
    })

</script>

<template>
    <div class="w-full p-5 flex gap-x-7">

        <div class="flex w-2/12 h-full flex-col gap-y-5 pt-2">

            <div>
                <AreaLabel value="Rate" />

                <div class="flex flex-col gap-y-2">
                    <div>
                        <RadioInput
                            value="hourly"
                            :disabled="shiftState.stay_type == null"
                            label="Hourly"
                            v-model="shiftState.charge_duration_rate_type"
                            @update:modelValue="clearRateFields"
                        />
                    </div>
                    <div>
                        <RadioInput
                            value="daily"
                            :disabled="shiftState.stay_type !== 'live_in'"
                            label="Daily"
                            v-model="shiftState.charge_duration_rate_type"
                            @update:modelValue="clearRateFields"
                        />
                    </div>
                </div>

            </div>

            <div v-if="props.client_type == 'client'">

                <AreaLabel class="text-nowrap" value="QuickBooks Rate" />

                <div class="flex flex-col gap-y-3">
                    <InputLabel
                        for="grid-hourly-charge-rate-amount"
                        value="Hourly"
                        class="text-xs text-stone-400"
                    >
                        <div class="flex justify-between border rounded bg-stone-100 w-full border-stone-400 p-2">
                            <span>
                                <span
                                    v-if="finalHourlyChargeAmount"
                                    :class="['font-bold pr-4', {}]"
                                >
                                    {{ finalHourlyChargeAmount }}
                                </span>
                            </span>
                            <span class="text-right"><LockClosedIcon class="flex-none inline-flex h-5 w-5 text-stone-400" /> </span>
                        </div>
                    </InputLabel>
                    <InputLabel
                        for="grid-daily-charge-rate-amount"
                        value="Daily"
                        class="text-xs text-stone-400"
                    >
                        <div class="flex justify-between border rounded bg-stone-100 w-full border-stone-400 p-2">
                            <span>
                                <span
                                    v-if="finalDailyChargeAmount"
                                    :class="['font-bold pr-4', {}]"
                                >
                                    {{ finalDailyChargeAmount }}
                                </span>
                            </span>
                            <span class="text-right"><LockClosedIcon class="flex-none inline-flex h-5 w-5 text-stone-400" /> </span>
                        </div>
                    </InputLabel>
                </div>

            </div>

            <div v-else>

                <AreaLabel class="text-nowrap" value="Rate/Range Quoted" />

                <div class="flex flex-col gap-y-3">
                    <InputLabel
                        for="grid-hourly-min-charge-rate-amount"
                        value="Minimum"
                        class="text-sm"
                        palette="blue"
                    >
                        <LwxSelect
                            class="mt-1"
                            id="grid-hourly-min-charge-rate-amount"
                            v-model="shiftState.min_charge_rate_amount"
                            :options="shiftState.charge_duration_rate_type === 'hourly' ? hourlyRates : dailyRates"
                        />
                    </InputLabel>
                    <InputLabel
                        for="grid-hourly-max-charge-rate-amount"
                        value="Maximum"
                        class="text-sm"
                        palette="blue"
                    >
                        <LwxSelect
                            class="mt-1"
                            id="grid-hourly-max-charge-rate-amount"
                            v-model="shiftState.max_charge_rate_amount"
                            :options="shiftState.charge_duration_rate_type === 'hourly' ? hourlyRates : dailyRates"
                        />
                    </InputLabel>
                </div>

            </div>



        </div>

        <div class="flex w-7/12 h-full">
            <div class="flex w-full h-full rounded-lg bg-lifeworx-blue-600 bg-opacity-5 border-2 border-dashed border-lifeworx-blue-600 p-5 flex-col gap-y-4">

                <h3 class="text-lifeworx-blue-600 w-full flex font-bold text-xl ">
                    <IconCalendarAdd28Regular class="flex-none flex-shrink-0 inline-flex h-7 w-7 mr-2" />
                    {{ actionAreaTitle }}
                </h3>

                <div class="w-full flex flex-row gap-x-2.5 pb-3 border-b border-opacity-50 border-lifeworx-blue-600">
                    <div class="flex flex-col w-full pb-3 py-2 pr-2.5 border-opacity-75 border-r border-lifeworx-blue-500">
                        <div class="flex flex-row gap-x-3">
                            <div>
                                <RadioInput
                                    value="live_in"
                                    label="Live-In"
                                    v-model="shiftState.stay_type"
                                />
                            </div>
                            <div>
                                <RadioInput
                                    value="live_out"
                                    label="Live-Out"
                                    v-model="shiftState.stay_type"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col w-full pb-3 py-2">
                        <div class="flex flex-row gap-x-3 pl-2">
                            <div>
                                <RadioInput
                                    value="ongoing"
                                    label="Ongoing"
                                    v-model="shiftState.duration_type"
                                />
                            </div>
                            <div>
                                <RadioInput
                                    value="temp"
                                    label="Temp"
                                    v-model="shiftState.duration_type"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex flex-row gap-x-5">

                    <div class="w-full">
                        <h4 class="font-semibold text-sm mb-1 text-lifeworx-blue-800">Days</h4>
                        <WeekDaySelect
                            :disabled="editMode"
                            v-model="shiftState.dayOfWeeks"
                        />
                    </div>

                    <div class="w-full flex flex-col gap-y-6">

                        <div>
                            <InputLabel
                                for="grid-start-time"
                                :value="shiftState.stay_type == 'live_out' ? 'Shift Hours' : '24hr Shift Start Time'"
                                palette="blue"
                            >
                                <TimeRangePicker
                                    :startTime="shiftState.start_time"
                                    :duration="shiftState.duration"
                                    :stayType="shiftState.stay_type"
                                    @update:startTime="updateStartTime"
                                    @update:duration="updateDuration"
                                />
                            </InputLabel>
                        </div>

                        <div>
                            <InputLabel
                                for="grid-start-date"
                                :value="shiftState.duration_type == 'temp' ? 'Job Start & End Date' : 'Job Start Date'"
                                palette="blue"
                            >
                                <DateRangePicker
                                    :startDate="shiftState.start_date"
                                    :endDate="shiftState.end_date"
                                    :durationType="shiftState.duration_type"
                                    @update:startDate="updateStartDate"
                                    @update:endDate="updateEndDate"
                                />
                            </InputLabel>

                        </div>

                    </div>

                </div>

            </div>
        </div>

        <div class="flex w-3/12 h-full flex-col gap-y-5 pt-2">

            <div>
                <AreaLabel value="Caregiver" />

                <div class="w-full">
                    <CaregiverSelect
                        id="grid-assigned-caregiver"
                        class="w-full text-stone-700 mb-2"
                        v-model="shiftState.assigned_caregiver"
                    />
                </div>
            </div>

            <div class="flex flex-col">
                <button
                    :disabled="shiftState.dayOfWeeks.length == 0"
                    @click="saveShifts"
                    :class="[
                        'w-full right-5 items-center border-0 h-12 rounded  text-white font-semibold transition-all duration-300 disabled:bg-stone-200',
                        {
                            'bg-lifeworx-blue-600 hover:bg-lifeworx-blue-700 shadow-sm': shiftState.dayOfWeeks.length != 0,
                            'bg-stone-200': shiftState.dayOfWeeks.length == 0
                        }
                    ]"
                >
                    {{ editMode ? 'Update Selected' : 'Add Shift' }}
                </button>

                <div class="mt-3 flex flex-row gap-x-3">
                    <button
                        :disabled="clearSectionButtonDisable"
                        @click="reset"
                        :class="[
                            'flex flex-grow rounded h-10 items-center justify-center text-center border-2 font-semibold text-xs',
                            {
                                'text-lifeworx-blue-700 border-lifeworx-blue-700 hover:text-lifeworx-blue-900 hover:border-lifeworx-blue-900 cursor-pointer shadow-sm': !clearSectionButtonDisable,
                                'text-stone-300 border-stone-300 disabled:text-stone-300 disabled:border-stone-300 cursor-default': clearSectionButtonDisable

                            }]"
                    >
                        Clear Selection
                    </button>
                    <button
                        :disabled="props.edit_state == null"
                        @click.stop="emit('shifts:delete')"
                        :class="[
                        'flex flex-grow rounded h-10 items-center justify-center text-center border-2 font-semibold text-xs',
                        {
                            'text-lifeworx-red-500 hover:text-lifeworx-red-800 border-lifeworx-red-500 hover:border-lifeworx-red-800 cursor-pointer shadow-sm': props.edit_state !== null,
                            'text-stone-300 border-stone-300 disabled:text-stone-300 disabled:border-stone-300 cursor-default': props.edit_state == null,
                        }]"
                    >
                        Delete Selected
                    </button>
                </div>

            </div>

        </div>
    </div>
</template>
