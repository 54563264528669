<script setup>
    import { ref, watch } from 'vue'
    import { storeToRefs } from 'pinia'
    import moment from 'moment'

    import { useClientSingleStore, useClientShiftStore, useFormStatusStore, useNotificationStore, useClientInvoiceShiftStore } from '@/Stores'
    import LatteClientScheduleBuilder from './LatteClientScheduleBuilder.vue'
    import LatteClientScheduleFooter from '@/Components/Client/LatteClientScheduleFooter.vue'

    import Loader from '@/Components/App/Loader.vue'
    import { debounce, deepCopy, isAttributeEqual, util } from '@/Helpers'
    import LatteClientScheduleView from '@/Components/Client/LatteClientScheduleView.vue'
    import DateRangeModal from '../DateRangeModal.vue'

    import { IconCalendarError24Filled } from '@iconify-prerendered/vue-fluent'

    const clientSingleStore = useClientSingleStore()
    const clientShiftStore = useClientShiftStore()
    const clientInvoiceShiftStore = useClientInvoiceShiftStore()
    const { client } = storeToRefs(clientSingleStore)
    const formStatus = useFormStatusStore()
    const notificationStore = useNotificationStore()

    const formLoading = formStatus.computedFormLoading('shiftForm')

    const plannedShifts = ref([])
    const refreshBuilder = ref(true)
    const confirmingShiftDeletion = ref(false)

    const props = defineProps({
        client_id : {
            type: Number,
            required: true
        },
        start_date: {
            type: String,
            required: true
        }
    })

    const emit = defineEmits(['builder-mode:change'])

    const selectedShiftIds = ref([])
    const dateRangeStartDate = ref(null)
    const builderStateInfo = ref(null)

    let clientShifts
    let shiftMap

    function transformShiftDataToShift(shiftData)
    {
        const shifts = []

        shiftData.dayOfWeeks.forEach(day => {
            const {dayOfWeeks, durationType, ...shift} = shiftData
            if (util.schedule.isDateOverlap(props.start_date, day, shift.start_date, shift.end_date, shift.start_time, shift.duration)) {

                const startDateTimeObj = moment(`${util.date.nextDayOfWeekDate(shift.start_date, day)} ${shift.start_time}`, util.schedule.client_date_time_input_format)
                const endDateTimeObj = shift.end_date ? moment(`${util.date.previousDayOfWeekDate(shift.end_date, day)} ${shift.start_time}`, util.schedule.client_date_time_input_format).add(shift.duration, 'minutes') : null

                const finalShift = {
                    ...shift,
                    day_of_week: day,
                    start_date: util.date.nextDayOfWeekDate(shift.start_date, day),
                    start_datetime: util.date.momentToUtcDateTime(startDateTimeObj),
                    end_date: util.date.previousDayOfWeekDate(shift.end_date, day),
                    end_datetime: endDateTimeObj ? util.date.momentToUtcDateTime(endDateTimeObj) : null,
                    assigned_caregiver_id: shift.assigned_caregiver.id
                }

                if (finalShift.charge_rate_amount == 'Multiple') {
                    const key = util.date.isWeekend(day) ? 'weekend' : 'weekday'
                    finalShift.charge_rate_amount = finalShift.charge_rate_amount_dict ? finalShift.charge_rate_amount_dict[key] : ''
                }

                shifts.push(finalShift)
            }
        })

        return shifts
    }

    function transformBuilderDataChange(shiftData) {
        if (selectedShiftIds.value.length) {
            return getProjectedSelectShifts(shiftData)
        } else {
            return transformShiftDataToShift(shiftData)
        }
    }

    function getProjectedSelectShifts(shiftData) {
        const change = getShiftChangeData(shiftData)
        return selectedShiftIds.value.map(shiftId => {
            const shift = deepCopy(clientShifts.value[shiftMap.value[shiftId]])
            Object.keys(change).forEach(key => {
                shift[key] = change[key]
            })

            return shift
        })
    }

    function getShiftChangeData(shiftData) {
        const originalState = getCommonShiftState(selectedShiftIds.value)
        const change = {}
        Object.keys(originalState).forEach(key => {
            if (!isAttributeEqual(originalState, shiftData, key)) {
                change[key] = shiftData[key]
            }
        })

        if (change['assigned_caregiver']) {
            change['assigned_caregiver_id'] = change['assigned_caregiver'].id ? change['assigned_caregiver'].id : null
        }

        delete change['dayOfWeeks']
        delete change['assigned_caregiver']

        if ('stay_type' in change && change['stay_type'] != originalState['stay_type']) {
            change['min_charge_rate_amount'] = change['min_charge_rate_amount'] ?? null
            change['max_charge_rate_amount'] = change['max_charge_rate_amount'] ?? null
        }

        if (change['duration_type'] == 'ongoing' && !change['start_date']) {
            change['start_date'] = originalState['start_date']
        }

        return change
    }

    let shiftDataToUpdate = []
    function handleShiftUpdate(shiftData) {
        const change = getShiftChangeData(shiftData)

        if (Object.keys(change).length == 0) {
            return
        }

        shiftDataToUpdate = []
        selectedShiftIds.value.forEach(id => {
            const shiftChange = {
                ...change,
                id: id
            }
            const shift = clientShifts.value[shiftMap.value[id]]

            if (shiftChange['end_date']) {
                shiftChange['end_date'] = util.date.previousDayOfWeekDate(shiftChange['end_date'], shift.day_of_week)
            }

            if (shiftChange['duration_type'] == 'ongoing') {
                shiftChange['start_date'] = util.date.nextDayOfWeekDate(shiftChange['start_date'], shift.day_of_week)
                shiftChange['end_date'] = null
            }

            const start_time = shiftData['start_time'] ? shiftData['start_time'] : shift.start_time
            const duration = shiftData['duration'] ? shiftData['duration'] : shift.duration

            //Convert to date time
            if (shiftChange['start_date']) {
                const dateObj = moment(`${util.date.nextDayOfWeekDate(shiftChange['start_date'], shift.day_of_week)} ${start_time}`, util.schedule.client_date_time_input_format)
                shiftChange['start_datetime'] = util.date.momentToUtcDateTime(dateObj)
            }

            if (shiftChange.hasOwnProperty('end_date')) {
                if (shiftChange['end_date'] == null) {
                    shiftChange['end_datetime'] = null
                } else {
                    const dateObj = moment(`${shiftChange.end_date} ${shiftData.start_time}`, util.schedule.client_date_time_input_format).add(duration, 'minutes')
                    shiftChange['end_datetime'] = util.date.momentToUtcDateTime(dateObj)
                }
            }

            shiftDataToUpdate.push(shiftChange)
        })

        const messages = getChangeErrorMessages(change)

        if (messages.length) {
            notificationStore.add('Error Saving Shifts', 'error', messages.join("\n"))
            return
        }

        if (isDateRangeModalNeeded(change)) {
            const originalState = getCommonShiftState(selectedShiftIds.value)
            dateRangeStartDate.value = originalState['start_date']
        } else {
            if (change['start_date'] || change['end_date']) {
                updateShiftsToBackEnd()
            } else {
                const originalState = getCommonShiftState(selectedShiftIds.value)
                const range_start_datetime = util.date.dateToUtcDateTime(originalState['start_date'])
                const range_end_datetime = originalState['end_date'] ? util.date.dateToUtcDateTime(originalState['end_date'], false) : null
                updateShiftsToBackEnd(range_start_datetime, range_end_datetime)
            }
        }
    }

    function getCreationErrorMessages(shiftData) {

        const messages = []

        if (shiftData['min_charge_rate_amount'] && shiftData['max_charge_rate_amount'] && shiftData['max_charge_rate_amount'] < shiftData['min_charge_rate_amount']) {
            messages.push('Max charge rate amount cannot be less than min charge rate amount')
        }

        if (shiftData.end_date != null) {
            shiftData.dayOfWeeks.forEach(day => {
                const dayOfWeekDate = util.date.nextDayOfWeekDate(shiftData['start_date'], day)
                if (dayOfWeekDate > shiftData['end_date']) {
                    messages.push(`New start and end date for shift with change start date ${shiftData.start_date} does not contain shift day of week: ${day}`)
                }
            })
        }

        return messages
    }

    function getChangeErrorMessages(change) {
        const messages = []

        if (change['min_charge_rate_amount'] && change['max_charge_rate_amount'] && change['max_charge_rate_amount'] < change['min_charge_rate_amount']) {
            messages.push('Max charge rate amount cannot be smaller than min charge rate amount')
        }

        selectedShiftIds.value.forEach(shiftId => {
            const shift = clientShifts.value[shiftMap.value[shiftId]]
            if (!change['charge_duration_rate_type'] || change['charge_duration_rate_type'] == shift['charge_duration_rate_type']) {
                if (change['min_charge_rate_amount'] && shift['max_charge_rate_amount'] && change['min_charge_rate_amount'] > shift['max_charge_rate_amount']) {
                    messages.push(`Min charge rate amount cannot be more than max charge rate amount for shift at date ${shift.start_date}`)
                }

                if (change['max_charge_rate_amount'] && shift['min_charge_rate_amount'] && change['max_charge_rate_amount'] < shift['min_charge_rate_amount']) {
                    messages.push(`Max charge rate amount cannot be less than min charge rate amount for shift at date ${shift.start_date}`)
                }
            }

            if (change['duration_type'] == 'temp' || shift['end_date']) {
                if (change['start_date'] && !change['end_date']) {
                    if (change['start_date'] > shift['start_date']) {
                        messages.push(`End date need to be entered for shift with change date ${change.start_date}`)
                    }
                }
                if (change['start_date'] && change['end_date']) {
                    const dayOfWeekDate = util.date.nextDayOfWeekDate(change['start_date'], shift['day_of_week'])
                    if (dayOfWeekDate > change['end_date']) {
                        messages.push(`New start and end date for shift with change start date ${change.start_date} does not contain shift day of week: ${shift.day_of_week}`)
                    }
                }
            }
        })

        return messages
    }

    const isDateRangeModalNeeded = (change) => {
        if (change['start_date'] || change['end_date']) {
            return false
        }

        return selectedShiftIds.value.some(shiftId => {
            const shift = clientShifts.value[shiftMap.value[shiftId]]
            if (shift.end_date == null) {
                return true
            }

            if (shift.end_date >= util.date.nextSunday(props.start_date)) {
                return true
            }
        })
    }

    function handleRangeDateUpdateShift(range_start_date, range_end_date)
    {
        //Recalculate the shift start and end date using range date
        shiftDataToUpdate = shiftDataToUpdate.map(shiftChange => {
            const shift = clientShifts.value[shiftMap.value[shiftChange['id']]]

            const duration = shiftChange['duration'] ? shiftChange['duration'] : shift['duration']
            const start_time = shiftChange['start_time'] ? shiftChange['start_time'] : shift['start_time']
            const end_date = shiftChange.hasOwnProperty('end_date') ? shiftChange['end_date'] : shift['end_date']
            const duration_type = shiftChange['duration_type'] ? shiftChange['duration_type'] : shift['duration_type']

            shiftChange['start_datetime'] = util.date.momentToUtcDateTime(
                moment(`${util.date.nextDayOfWeekDate(range_start_date, shift.day_of_week)} ${start_time}`, util.schedule.client_date_time_input_format)
            )

            if (range_end_date != null) {
                if (duration_type == 'ongoing' && (shiftChange['duration'] || shiftChange['start_time'])) {
                    if (end_date == null) {
                        shiftChange['end_datetime'] = util.date.momentToUtcDateTime(
                            moment(`${util.date.previousDayOfWeekDate(range_end_date, shift.day_of_week)} ${start_time}`, util.schedule.client_date_time_input_format)
                                .add(duration, 'minutes')
                        )
                    } else {
                        const smallerEndDate = range_end_date <= end_date ? range_end_date : end_date
                        shiftChange['end_datetime'] = util.date.momentToUtcDateTime(
                            moment(`${util.date.previousDayOfWeekDate(smallerEndDate, shift.day_of_week)} ${start_time}`, util.schedule.client_date_time_input_format)
                                .add(duration, 'minutes')
                        )
                    }
                }
            }

            return shiftChange
        })

        const range_start_datetime = util.date.dateToUtcDateTime(range_start_date)
        const range_end_datetime = range_end_date ? util.date.dateToUtcDateTime(range_end_date, false) : null
        updateShiftsToBackEnd(range_start_datetime, range_end_datetime)
        dateRangeStartDate.value = null
    }

    function updateShiftsToBackEnd(range_start_datetime = null, range_end_datetime = null)
    {
        if (shiftDataToUpdate.length == 0) {
            return
        }

        clientShiftStore.updateShifts(client.value.id, shiftDataToUpdate, range_start_datetime, range_end_datetime, 'shiftForm')
            .then(response => {
                if (response.status != 204) {
                    clientShiftStore.addOrReplaceClientShiftInLocalStore(client.value.id, response.data)
                    notificationStore.add(`Updated ${shiftDataToUpdate.length > 1 ? 'shifts' : 'shift'} successfully`)
                    shiftDataToUpdate = []
                    selectedShiftIds.value = []
                }
            })
    }

    function handleShiftCreate(shiftData)
    {
        const errorMessages = getCreationErrorMessages(shiftData)

        if (errorMessages.length) {
            notificationStore.add('Error Creating Shifts', 'error', errorMessages.join("\n"))
            return
        }

        const shifts = transformShiftDataToShift(shiftData)

        clientShiftStore.storeShift(client.value, shifts, 'shiftForm')
            .then(function (response) {
                clientShiftStore.addClientShiftsToLocalStore(client.value.id, response.data)
                plannedShifts.value = []
                refreshBuilder.value = true
                notificationStore.add(`Created ${shifts.length > 1 ? 'shifts' : 'shift'} successfully`)
            })
            .catch(function(error) {
                notificationStore.add('Error Saving Shifts', 'error', 'Please try again')
            })
            .finally(function() {
            })
    }

    function retrieveShifts() {
        if (!props.client_id) {
            return
        }

        if (props.start_date >= util.date.lastSunday()) {
            const upperBoundDateTime = util.date.nextUtcDateTime(props.start_date, 7 * 5, false)
            const lowerBoundDateTime = util.date.dateToUtcDateTime(props.start_date)
            clientShiftStore.retrieveShifts(props.client_id, lowerBoundDateTime, upperBoundDateTime, 'shiftForm')
                .then(response => {
                    clientShiftStore.replaceLocalStoreClientShifts(props.client_id, response.data)
                })
        } else {
            const upperBound = util.date.nextDate(props.start_date, 6)
            clientInvoiceShiftStore.retrieveShifts(props.client_id, props.start_date, upperBound, 'shiftForm')
                .then(response => {
                    clientInvoiceShiftStore.replaceLocalStoreClientShifts(props.client_id, response.data)
                })
        }

    }

    function getCommonShiftState(ids) {
        if (ids.length == 0) {
            return null
        }

        const state = {
            stay_type: undefined,
            start_time: undefined,
            duration: undefined,
            assigned_caregiver: undefined,
            assigned_caregiver_id: undefined,
            charge_duration_rate_type: undefined,
            charge_rate_amount: undefined,
            min_charge_rate_amount: undefined,
            max_charge_rate_amount: undefined
        }
        const keys = Object.keys(state)

        clientShifts.value.forEach(shift => {

            if (ids.includes(shift.id) == false) {
                return
            }

            if (state['dayOfWeeks'] === undefined) {
                state.dayOfWeeks = []
            }
            state['dayOfWeeks'].push(shift.day_of_week)

            if (state['duration_type'] !== null) {
                const shiftDurationType = shift.end_date == null ? 'ongoing' : 'temp'
                if (state['duration_type'] === undefined) {
                    state['duration_type'] = shiftDurationType
                } else if (state['duration_type'] != shiftDurationType) {
                        state['duration_type'] = null
                }
            }

            let shiftStartDate = util.schedule.getWeekSpecificDate(props.start_date, shift.day_of_week)
            if (shift.day_of_week == 'saturday' && util.schedule.is2DaysTime(shift.start_time, shift.duration)) {
                if (shift.start_date < props.start_date && props.start_date > util.date.lastSunday()) {
                    shiftStartDate = util.date.previousDate(props.start_date)
                }
            }

            if (state['start_date'] === undefined) {
                state['start_date'] = shiftStartDate
            } else if (state['start_date'] > shiftStartDate) {
                state['start_date'] = shiftStartDate
            }

            if (state['end_date'] === undefined) {
                state['end_date'] = shift['end_date']
            } else if (shift['end_date'] == null) {
                state['end_date'] = null
            } else if (state['end_date'] != null && shift['end_date'] != null) {
                state['end_date'] = shift['end_date'] >= state['end_date'] ? shift['end_date'] : state['end_date']
            }

            keys.forEach(key => {
                if (state[key] === undefined) {
                    state[key] = shift[key]
                } else if (!isAttributeEqual(state, shift, key)) {
                    state[key] = null
                }
            })
        })

        if (state['stay_type'] == null) {
            state['charge_duration_rate_type'] = null
        }

        if (state['start_time']) {
            state['start_time'] = state['start_time'].slice(0, 5)
        }

        return state
    }

    const retrieveShiftDebounce = debounce(() => {
        retrieveShifts()
    }, 300)

    const confirmShiftDeletion = () => {
        confirmingShiftDeletion.value = true;
        const originalState = getCommonShiftState(selectedShiftIds.value)
        dateRangeStartDate.value = originalState['start_date']
    }

    const dayOfWeekOrder = {
        'sunday': 0,
        'monday': 1,
        'tuesday': 2,
        'wednesday': 3,
        'thursday': 4,
        'friday': 5,
        'saturday': 6
    };

    function sortByDayAndTime(a, b) {
        const dayA = dayOfWeekOrder[a.day_of_week.toLowerCase()]
        const dayB = dayOfWeekOrder[b.day_of_week.toLowerCase()]

        if (dayA !== dayB) {
            return dayA - dayB
        }

        return moment(a.start_time, 'HH:mm:ss').diff(moment(b.start_time, 'HH:mm:ss'))
    }

    async function saveShiftsToClipboard() {
        const shiftText = shiftsToText()
        if (document.hasFocus()) {
            await navigator.clipboard.writeText(shiftText)
        }
    }

    function shiftsToText() {
        let text = ''

        clientShifts.value
            .filter(shift => util.schedule.isDateOverlap(props.start_date, shift.day_of_week, shift.start_date, shift.end_date, shift.start_time, shift.duration))
            .sort(sortByDayAndTime)
            .forEach(shift => {
                let shiftText = util.date.getTimeRangeText(shift.day_of_week, shift.start_time, shift.duration)

                if (shift.assigned_caregiver?.first_name) {
                    shiftText += ' - ' + shift.assigned_caregiver.first_name
                }

                text += shiftText + '\n'
            })

        return text
    }

    function handleRangeDateDeleteShift(start_date, end_date) {
        deleteShiftsToBackEnd(start_date, end_date)
        dateRangeStartDate.value = null
        confirmingShiftDeletion.value = false
    }

    function deleteShiftsToBackEnd(start_date, end_date = null)
    {
        const start_datetime = util.date.dateToUtcDateTime(start_date)
        const end_datetime = end_date ? util.date.dateToUtcDateTime(end_date, false) : null

        clientShiftStore.deleteShifts(client.value.id, selectedShiftIds.value, start_datetime, end_datetime, 'shiftForm')
            .then(response => {
                retrieveShifts()
                shiftDataToUpdate = []
                selectedShiftIds.value = []
                notificationStore.add(`Deleted Shift(s) Successfully`)
            })
            .catch(function(error) {
                notificationStore.add('Error Deleting Shift(s)', 'error', 'Please try again')
            })
            .finally(function() {
            })
    }

    function handleRangeSave(start_date, end_date) {
        if (confirmingShiftDeletion.value) {
            handleRangeDateDeleteShift(start_date, end_date);
        } else {
            handleRangeDateUpdateShift(start_date, end_date);
        }
    }

    function handleBuilderShiftDataChange(info) {
        plannedShifts.value = transformBuilderDataChange(info)
        refreshBuilder.value = false
        builderStateInfo.value = info
    }

    const emitBuilderMode = () => {
        if (props.start_date < util.date.lastSunday()) {
            emit('builder-mode:change', null)
        }

        const mode = selectedShiftIds.value.length == 0 ? 'add' : 'edit'
        emit('builder-mode:change', mode)
    }

    watch([() => props.start_date, () => props.client_id], (newValue) => {
        retrieveShiftDebounce()
        emitBuilderMode()
        if (builderStateInfo.value) {
            plannedShifts.value = transformBuilderDataChange(builderStateInfo.value)
        }
    }, {immediate: true})

    watch(selectedShiftIds, (newValue) => {
        emitBuilderMode()
    })

    watch([() => props.client_id, () => props.start_date], ([client_id, start_date]) => {
        if (start_date >= util.date.lastSunday()) {
            clientShifts = clientShiftStore.computedClientShift(client_id)
            shiftMap = clientShiftStore.computedClientShiftMap(client_id)
        } else {
            clientShifts = clientInvoiceShiftStore.computedClientShift(client_id)
        }
    }, { immediate: true})

</script>

<template>
    <div class="relative">
        <div
            v-if="formLoading"
            class="absolute z-overlay w-full h-full flex items-center space-around"
        >
            <div class="bg-lifeworx-blue-800 opacity-50 w-full h-full absolute" />
            <Loader />
        </div>

        <div
            v-if="!formLoading && clientShifts.length == 0 && props.start_date < util.date.lastSunday()"
            class="absolute z-content w-full h-full flex items-center space-around"
        >
            <div class="w-full h-full flex flex-col bg-gradient-radial from-stone-100/100 via-stone-100/50 to-white/50 justify-center items-center">
                <IconCalendarError24Filled class="flex-none flex-shrink-0 inline-flex h-14 w-14 mb-2 text-stone-400/75" />
                <h3 class="text-2xl font-semibold text-stone-400/75">No Shifts This Week</h3>
            </div>
        </div>


        <div class="z-content" id="client_schedule">
            <LatteClientScheduleBuilder
                v-if="props.start_date >= util.date.lastSunday()"
                :start_date="props.start_date"
                :client_type="client.type"
                :edit_state="getCommonShiftState(selectedShiftIds)"
                :refresh="refreshBuilder"
                :charge_rates="client.charge_rates"
                @shifts:save="(info) => handleShiftCreate(info)"
                @shifts:update="(info, range) => handleShiftUpdate(info, range)"
                @shifts-validated-data:change="handleBuilderShiftDataChange"
                @builder-form:reset="() => selectedShiftIds = []"
                @shifts:delete="confirmShiftDeletion"
            />
            <LatteClientScheduleView
                :start_date="props.start_date"
                :client="client"
                :planned_shifts="plannedShifts"
                :selected_shift_ids="selectedShiftIds"
                @shifts:selected="(ids) => selectedShiftIds = ids"
                :formLoading="formLoading"
            />

            <LatteClientScheduleFooter
                :client="client"
                :selected_shift_ids="selectedShiftIds"
                :planned_shifts="plannedShifts"
                :start_date="props.start_date"
                @shifts:copy="saveShiftsToClipboard"
            />

            <DateRangeModal
                @range:cancel="() => {
                    dateRangeStartDate = null;
                    confirmingShiftDeletion = false;
                }"
                notice="Selecting a partial range will split shifts into separate series."
                :title="confirmingShiftDeletion ? 'Delete Selected Shifts' : 'Update Selected Shifts'"
                :description="confirmingShiftDeletion ? 'You can delete some or all shifts in the selected series.' : 'Your changes can be applied to some or all shifts in the selected series.'"
                :start_date="dateRangeStartDate"
                :button_text="confirmingShiftDeletion ? 'Delete' : 'Update'"
                v-bind="confirmingShiftDeletion ? { button_style: 'bg-lifeworx-red-500 hover:bg-lifeworx-red-800 focus:bg-lifeworx-red-500 active:bg-lifeworx-red-800 focus:ring-lifeworx-red-500' } : {}"
                @range:save="handleRangeSave"
            />
        </div>
    </div>
</template>
