<script setup>

    import { ref, onMounted, onBeforeUnmount } from 'vue'
    import { RouterView } from 'vue-router'
    import { storeToRefs } from 'pinia'

    import { useAuthStore, useConfigStore, useViewStore } from '@/Stores'

    import Notification from '@/Components/App/Notification.vue'
    import LattePageNav from '@/Components/App/LattePageNav.vue'
    import LatteActionModal from '@/Components/App/LatteActionModal.vue'
    import { util } from '@/Helpers'

    import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout.vue'
    import GuestLayout from '@/Layouts/GuestLayout.vue'

    import Loader from '@/Components/App/Loader.vue'
    import { ArrowUturnUpIcon } from '@heroicons/vue/20/solid'

    const configStore = useConfigStore()
    const authStore = useAuthStore()
    const viewStore = useViewStore()

    const { config } = storeToRefs(configStore)
    const { auth, user } = storeToRefs(authStore)
    const { scrollDist, isEmbedded } = storeToRefs(viewStore)

    const data = ref({})

    const mountEl = document.querySelector("#app")

    config.value = { ...config.value, ...JSON.parse(mountEl.dataset.config) }
    data.value = JSON.parse(mountEl.dataset.data)

    authStore.user = mountEl.dataset.user ? JSON.parse(mountEl.dataset.user) : null
    authStore.auth.authenticated = authStore.user && authStore.user.user_id ? true : false
    authStore.auth.loading = false

    const handleScroll = (e) => {
        scrollDist.value = window.scrollY
    }

    onMounted(() => {
        isEmbedded.value = (window.location.href.includes('/embedded/') || data.value?.redirect?.includes('/embedded/'))
        document.addEventListener('scroll', handleScroll)

        setUpHandleParentDocumentClick()
    })

    onBeforeUnmount(() => {
        document.removeEventListener('scroll', handleScroll)
    })

    function setUpHandleParentDocumentClick() {
        window.addEventListener('message', receiveMessage, false);

        function closeDateTimePickerMenu() {
            const escEvent = new KeyboardEvent('keydown', {
                key: 'Escape',
                code: 'Escape',
                keyCode: 27, // Deprecated, but some older browsers may use it
                which: 27,   // Deprecated, but included for compatibility
                bubbles: true,
            })
            const element = document.querySelector('.dp__menu')
            if (element) {
                element.dispatchEvent(escEvent)
            }
        }

        function receiveMessage(event) {
            var message = event.data;

            if (message.type == 'parent-document-click') {
                closeDateTimePickerMenu()
            }
        }
    }


</script>

<style>

    .dp__theme_light {
        --dp-primary-color: #008AAB;
        --dp-primary-disabled-color: #005D73;
        --dp-success-color: #3F911A;
        --dp-success-color-disabled: #347715;
        --dp-danger-color: #AB2100;
        --dp-marker-color: #AB2100;
        --dp-input-padding: 8px 12px;
        --dp-font-size: 0.8rem;
        --dp-time-font-size: 1.25rem;
        --dp-action-buttons-padding: 4px 35px;
    }

    .dp__time_col_block {
        transform: rotate(180deg);
    }
    .dp__time_display_block {
        transform: rotate(180deg);
    }

    .dp--menu-wrapper {
        z-index: 8000;
    }

</style>

<template>

    <div>

        <AuthenticatedLayout
            v-if="auth.authenticated && user"
            :config="config"
            :user="user"
            :data="data"
        >

            <Loader
                v-if="auth.loading"
                class="my-36"
            />

            <RouterView
                v-else
                :data="data"
            />

        </AuthenticatedLayout>

        <GuestLayout
            v-else
            :config="config"
            :data="data"
        >

            <Loader
                v-if="auth.loading"
                class="my-36"
            />

            <RouterView
                v-else
                :data="data"
            />

        </GuestLayout>

        <Notification
            class="z-notifications"
        />

        <LatteActionModal />

        <button
            @click="util.scrollToElement('app')"
            class="fixed z-floating-nav right-5 p-4 border-0 w-14 h-14 rounded-full shadow-md bg-lifeworx-blue-600 hover:bg-lifeworx-blue-700 text-white text-lg font-semibold transition-all duration-300"
            :class="{
                'bottom-5': scrollDist > 500,
                '-bottom-28 ': scrollDist <= 500,
                }"
        >
            <ArrowUturnUpIcon class="w-6 h-6" />
            <span class="sr-only">Back to top</span>
        </button>

        <LattePageNav />

    </div>

</template>
